import {
    reactive,
    computed,
    onBeforeMount
} from 'vue';
import {
    Title,
    Checkbox,
    Dropdown,
    Input,
    Button
} from 'account-ui-components';
import Radio from '@/components/Radio/Radio.vue';

export default {
    name: 'ClientSettings',

    components: {
        Title,
        Checkbox,
        Dropdown,
        Input,
        Button,
        Radio
    },

    props: {
        loading: {
            type: Object,
            required: true
        },
        client: {
            type: Object,
            default: null
        },
        grants: {
            type: Array,
            required: true
        },
        onSave: {
            type: Function,
            required: true
        }
    },

    setup(props) {
        const radioItems = [
            {
                label: 'True',
                value: true
            },
            {
                label: 'False',
                value: false
            }
        ];
        const tokenUsageItems = [
            {
                label: 'Multiple',
                value: 0
            },
            {
                label: 'Once',
                value: 1
            }
        ];
        const tokenExpirationItems = [
            {
                label: 'Absolute',
                value: 0
            },
            {
                label: 'Sliding',
                value: 1
            }
        ];
        const classificationItems = [
            {
                label: 'Other',
                value: ''
            },
            {
                label: 'Third Party',
                value: 'thirdParty'
            },
            {
                label: 'Mission Critical',
                value: 'missionCritical'
            },
            {
                label: 'Internal Tool',
                value: 'internalTool'
            }
        ];
        const dPoPValidationModeItems = [
            {
                label: 'Custom',
                value: 0
            },
            {
                label: 'Iat',
                value: 1
            },
            {
                label: 'Nonce',
                value: 2
            },
            {
                label: 'IatAndNonce',
                value: 3
            }
        ];
        let initialGrants = [];
        const fields = reactive({
            enabled: null,
            allowOfflineAccess: null,
            requireClientSecret: null,
            requireConsent: null,
            requirePkce: null,
            allowPlainTextPkce: null,
            updateAccessTokenClaimsOnRefresh: null,
            alwaysSendClientClaims: null,
            backChannelLogoutSessionRequired: null,
            frontChannelLogoutSessionRequired: null,
            clientClaimsPrefix: null,
            backChannelLogoutUri: null,
            frontChannelLogoutUri: null,
            identityTokenLifetime: null,
            accessTokenLifetime: null,
            authorizationCodeLifetime: null,
            absoluteRefreshTokenLifetime: null,
            slidingRefreshTokenLifetime: null,
            refreshTokenUsage: null,
            refreshTokenExpiration: null,
            deviceCodeLifetime: null,
            classification: null,
            description: null,
            clientName: null,
            requireDPoP: null,
            dPoPValidationMode: null,
            dPoPClockSkew: null,
            requirePushedAuthorization: null,
            pushedAuthorizationLifetime: null,
            grants: []
        });
        const clientChanged = computed(() => (
            Object.keys(fields).some((field) => {
                if (field === 'grants') {
                    return initialGrants.sort().join('') !== fields.grants.sort().join('');
                }

                return String(props.client[field]) !== String(fields[field]);
            })
        ));

        onBeforeMount(() => {
            Object.keys(fields).forEach((field) => {
                fields[field] = props.client[field];
            });
            const grants = props.client.grants.map((grant) => grant.grantType);

            fields.grants = grants;
            initialGrants = grants;
        });

        const onDropdownChange = ({ name, value }) => {
            fields[name] = value;
        };

        const onInputChange = (event) => {
            const {
                name,
                value
            } = event.target;

            fields[name] = value;
        };

        const onSaveClick = () => {
            props.onSave(fields);
        };

        return {
            radioItems,
            tokenUsageItems,
            tokenExpirationItems,
            classificationItems,
            dPoPValidationModeItems,
            fields,
            clientChanged,
            onDropdownChange,
            onInputChange,
            onSaveClick
        };
    }
};
