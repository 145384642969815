import Alert from '@/api/services/alert';
import Api from '@/api';

function capitalizeAndSplitClassification(client) {
    let caplitalizedAndSplitClassifications;
    if (client.classification) {
        caplitalizedAndSplitClassifications = client.classification.replace(/([A-Z])/g, ' $1');
        caplitalizedAndSplitClassifications = caplitalizedAndSplitClassifications.charAt(0).toUpperCase() + caplitalizedAndSplitClassifications.slice(1);
    } else {
        caplitalizedAndSplitClassifications = 'Other';
    }
    return {
        ...client,
        classification: caplitalizedAndSplitClassifications
    };
}

export default {
    async getClients({ commit }, page = 1) {
        const pageSize = 10;
        let clients = {};

        try {
            const { data } = await Api.getClients({
                page,
                pageSize
            });
            clients = data;
            clients.items = clients.items.map((client) => capitalizeAndSplitClassification(client));

            if (page === 1) {
                commit('getClients', clients);
            } else {
                commit('getMoreClients', {
                    clients,
                    page
                });
            }
        } catch (err) {
            throw new Error(err);
        }

        return clients;
    },

    async getClientTypes({ commit }) {
        const { data } = await Api.getClientTypes();

        commit('getClientTypes', data);
    },

    async getClient({ commit }, id) {
        const { data } = await Api.getClient(id);
        const redirects = await Api.getClientRedirects(id);
        const scopes = await Api.getClientScopes(id);
        const claims = await Api.getClientClaims(id);
        const postLogoutRedirectUris = await Api.getClientPostLogoutRedirectUris(id);
        const secrets = await Api.getClientSecrets(id);
        const client = {
            ...data,
            redirects: redirects.data,
            scopes: scopes.data,
            claims: claims.data,
            postLogoutRedirectUris: postLogoutRedirectUris.data,
            secrets: secrets.data
        };

        commit('getClient', {
            id,
            client
        });
    },

    async updateClient({ state, commit, dispatch }, payload) {
        const {
            id,
            data
        } = payload;
        const {
            allowOfflineAccess,
            clientClaimsPrefix,
            enabled,
            requireClientSecret,
            requireConsent,
            requirePkce,
            allowPlainTextPkce,
            identityTokenLifetime,
            accessTokenLifetime,
            authorizationCodeLifetime,
            absoluteRefreshTokenLifetime,
            slidingRefreshTokenLifetime,
            refreshTokenUsage,
            updateAccessTokenClaimsOnRefresh,
            refreshTokenExpiration,
            deviceCodeLifetime,
            alwaysSendClientClaims,
            backChannelLogoutSessionRequired,
            frontChannelLogoutSessionRequired,
            backChannelLogoutUri,
            frontChannelLogoutUri,
            clientName,
            classification,
            requireDPoP,
            dPoPValidationMode,
            dPoPClockSkew,
            requirePushedAuthorization,
            pushedAuthorizationLifetime
        } = data;
        const {
            clientId,
            protocolType,
            description,
            allowRememberConsent,
            alwaysIncludeUserClaimsInIdToken,
            requireRequestObject,
            allowAccessTokensViaBrowser,
            accessTokenType,
            enableLocalLogin,
            includeJwtId,
            nonEditable,
            grants
        } = state.client[id];

        const oldGrants = grants.map((grant) => grant.grantType);
        const grantsToAdd = data.grants.filter((grant) => !oldGrants.includes(grant));
        const grantsToRemove = oldGrants.filter((grant) => !data.grants.includes(grant));

        const promises = [];

        promises.push(grantsToAdd.map(async (grant) => {
            await Api.addClientGrant(id, grant);
        }));

        promises.push(grantsToRemove.map(async (grant) => {
            const grantId = grants.find((item) => item.grantType === grant).id;
            await Api.removeClientGrant(id, grantId);
        }));

        await Promise.all(promises);

        await Api.updateClient(id, {
            id,
            clientId,
            clientName,
            allowOfflineAccess,
            clientClaimsPrefix,
            enabled,
            protocolType,
            requireClientSecret,
            description: description || 'test',
            requireConsent,
            allowRememberConsent,
            alwaysIncludeUserClaimsInIdToken,
            requirePkce,
            allowPlainTextPkce,
            requireRequestObject,
            allowAccessTokensViaBrowser,
            frontChannelLogoutSessionRequired,
            backChannelLogoutSessionRequired,
            identityTokenLifetime,
            accessTokenLifetime,
            authorizationCodeLifetime,
            absoluteRefreshTokenLifetime,
            slidingRefreshTokenLifetime,
            refreshTokenUsage,
            updateAccessTokenClaimsOnRefresh,
            refreshTokenExpiration,
            accessTokenType,
            enableLocalLogin,
            includeJwtId,
            alwaysSendClientClaims,
            backChannelLogoutUri,
            frontChannelLogoutUri,
            deviceCodeLifetime,
            nonEditable,
            classification,
            requireDPoP,
            dPoPValidationMode,
            dPoPClockSkew,
            requirePushedAuthorization,
            pushedAuthorizationLifetime
        });

        dispatch('getClientGrants', id);

        commit('updateClient', { id, classification });
    },

    async createClient({ commit }, client) {
        const { data } = await Api.createClient(client);

        Alert.success('Created successfully');

        commit('createClient', capitalizeAndSplitClassification(data));
    },

    async deleteClient({ commit }, clientId) {
        await Api.deleteClient(clientId);

        Alert.success(`Client ${clientId} was deleted successfully`, true);

        commit('deleteClient', clientId);
    },

    async getClientGrants({ commit }, clientId) {
        const { data } = await Api.getClientGrants(clientId);

        commit('getClientGrants', {
            clientId,
            data
        });
    },

    async getResources({ commit }) {
        const { data } = await Api.getResources();

        commit('getResources', data);
    },

    async getScopes({ commit }, id) {
        const { data } = await Api.getScopes(id);

        commit('getScopes', {
            id,
            data
        });
    },

    async addClientScope({ commit }, payload) {
        const {
            clientId,
            scope
        } = payload;
        const { data } = await Api.addClientScope(clientId, scope);

        Alert.success('Created successfully');

        commit('addClientScope', {
            clientId,
            data
        });
    },

    async removeClientScope({ commit }, payload) {
        const {
            clientId,
            scope
        } = payload;

        await Api.removeClientScope(clientId, scope);

        commit('removeClientScope', {
            clientId,
            scope
        });
    },

    async addClientRedirect({ commit }, payload) {
        const {
            clientId,
            uri
        } = payload;
        const { data } = await Api.addClientRedirect(clientId, uri);

        Alert.success('Created successfully');

        commit('addClientRedirect', {
            clientId,
            data
        });
    },

    async removeClientRedirect({ commit }, payload) {
        const {
            clientId,
            redirect
        } = payload;

        await Api.removeClientRedirect(clientId, redirect.id);

        commit('removeClientRedirect', {
            clientId,
            redirectId: redirect.id
        });
    },

    async addClientSecret({ commit }, payload) {
        const {
            clientId,
            secret
        } = payload;
        const { data } = await Api.addClientSecret(clientId, secret);

        Alert.success('Created successfully');

        commit('addClientSecret', {
            clientId,
            data
        });

        return data.secret;
    },

    async removeClientSecret({ commit }, payload) {
        const {
            clientId,
            secret
        } = payload;

        await Api.removeClientSecret(clientId, secret.id);

        commit('removeClientSecret', {
            clientId,
            secretId: secret.id
        });
    },

    async addClientClaim({ commit }, payload) {
        const {
            clientId,
            claim
        } = payload;
        const { data } = await Api.addClientClaim(clientId, claim);

        Alert.success('Created successfully');

        commit('addClientClaim', {
            clientId,
            data
        });
    },

    async removeClientClaim({ commit }, payload) {
        const {
            clientId,
            claim
        } = payload;

        await Api.removeClientClaim(clientId, claim.id);

        commit('removeClientClaim', {
            clientId,
            claimId: claim.id
        });
    },

    async addClientPostLogoutRedirectUri({ commit }, payload) {
        const {
            clientId,
            uri
        } = payload;
        const { data } = await Api.addClientPostLogoutRedirectUri(clientId, uri);

        Alert.success('Created successfully');

        commit('addClientPostLogoutRedirectUri', {
            clientId,
            data
        });
    },

    async removeClientPostLogoutRedirectUri({ commit }, payload) {
        const {
            clientId,
            uri
        } = payload;

        await Api.removeClientPostLogoutRedirectUri(clientId, uri.id);

        commit('removeClientPostLogoutRedirectUri', {
            clientId,
            uriId: uri.id
        });
    },

    async addClientCors({ commit }, payload) {
        const {
            clientId,
            origin
        } = payload;
        const { data } = await Api.addClientCors(clientId, origin);

        Alert.success('Created successfully');

        commit('addClientCors', {
            clientId,
            data
        });
    },

    async removeClientCors({ commit }, payload) {
        const {
            clientId,
            originId
        } = payload;

        await Api.removeClientCors(clientId, originId);

        commit('removeClientCors', {
            clientId,
            uriId: originId
        });
    },

    async removeClientResource({ commit }, payload) {
        const {
            clientId,
            section,
            resourceId
        } = payload;

        if (section === 'postLogoutRedirectUris') {
            await Api.removeClientPostLogoutRedirectUri(clientId, resourceId);
        }

        Alert.success('Removed successfully');

        commit('removeClientResource', {
            clientId,
            section,
            resourceId
        });
    },

    async duplicateClient({ commit }, payload) {
        const {
            clientId,
            newClientName
        } = payload;

        const { data } = await Api.duplicateClient(clientId, newClientName);

        commit('duplicateClient');

        return data.id;
    },

    async uploadClientLogo({ commit, dispatch }, payload) {
        const {
            clientId,
            blob
        } = payload;

        await Api.uploadClientLogo(clientId, blob);

        dispatch('getClient', clientId);

        commit('uploadClientLogo');
    },

    async removeClientLogo({ commit, dispatch }, payload) {
        await Api.removeClientLogo(payload);

        dispatch('getClient', payload);

        commit('removeClientLogo');
    }
};
